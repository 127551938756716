import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import PageTemplate from "../templates/PageTemplate";
import moment from "moment/moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function Finds() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getFinds();
  }, []);

  async function updatePaid(id) {
    await updateDoc(doc(db, "finds", id), {
      status: "ended",
      paymentStatus: "paid",
    });
    alert("Confirmation de paiement éffectuée avec succès")
    getFinds();
  }

  async function getFinds() {
    const q = query(collection(db, "finds"));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      const getFindName = await getDoc(doc(db, "users", res.data().userId));
      dataArr.push({
        id: res.id,
        ...res.data(),
        finderName:
          getFindName.data().lastName + " " + getFindName.data().firstName,
        finderEmail: getFindName.data().email,
      });

      setData(dataArr);
    });
    setIsLoading(false);
  }

  if (isLoading) return null;
  return (
    <PageTemplate
      title={"Véhicules retrouvés" + " (" + data.length + ")"}
      content={
        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Plaque</TableCell>
                  <TableCell>Trouveur</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Trouvé le</TableCell>
                  <TableCell>Terminé ?</TableCell>
                  <TableCell>A été payé(e)</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.searchPlate}
                    </TableCell>
                    <TableCell>{row.finderName}</TableCell>
                    <TableCell>{row.finderEmail}</TableCell>
                    <TableCell>
                      {moment(row.createdAt).format("DD/MM/YYYY h:mm a")}
                    </TableCell>
                    <TableCell>
                      {row.status == "ended" ? (
                        <i class="fa-solid fa-check text-xl text-green-500"></i>
                      ) : (
                        <i class="fa-solid fa-x text-xl text-red-500"></i>
                      )}
                    </TableCell>

                    <TableCell>
                      {row.paymentStatus == "paid" ? (
                        <i class="fa-solid fa-check text-xl text-green-500"></i>
                      ) : (
                        <i class="fa-solid fa-x text-xl text-red-500"></i>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.paymentStatus == "unpaid" && (
                        <button
                          className="hover:bg-gray-100 rounded-xl p-2 flex flex-row items-center"
                          onClick={() => updatePaid(row.id)}
                        >
                          <span>Marquer comme payé</span>
                          <i class="ml-2 fa-solid fa-check text-xl text-green-500"></i>
                        </button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    />
  );
}
