import Login from "./pages/Auth/Login";
import Categories from "./pages/Categories";
import Home from "./pages/Home";
import PageTemplate from "./templates/PageTemplate";
import { onAuthStateChanged } from "firebase/auth";
import { Routes, Route } from "react-router-dom";
import Searchs from "./pages/Searchs";
import { useEffect, useState } from "react";
import { auth } from "./config/firebase";
import Matchs from "./pages/Matchs";
import Users from "./pages/Users";
import Finds from "./pages/Finds";
import MatchDetails from "./pages/MatchDetails";
import CGV from "./pages/CGV";
import HowItsWork from "./pages/HowItsWork";
import Mentions from "./pages/Mentions";

function App() {
  const [authenticated, setAuthenticated] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const unsusbcribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoaded(true);
    });
    return () => unsusbcribe();
  };

  return authenticated ? (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/users" element={<Users />} />
      <Route path="/searchs" element={<Searchs />} />
      <Route path="/matchs" element={<Matchs />} />
      <Route path="/matchs/:id" element={<MatchDetails />} />
      <Route path="/finds" element={<Finds />} />
      <Route path="/categories" element={<Categories />} />
      <Route path="/cgv" element={<CGV />} />
      <Route path="/howitswork" element={<HowItsWork />} />
      <Route path="/mentions" element={<Mentions />} />
    </Routes>
  ) : (
    <Routes>
      <Route path="/" element={<Login />} />
    </Routes>
  );
}

export default App;
