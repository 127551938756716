import React from "react";
import { auth } from "../config/firebase";
import { signOut } from "firebase/auth";
import { redirect, useLocation, useNavigate } from "react-router-dom";

export default function Sidebar() {
  const CLASS_ACTIVE = "bg-blue-100 p-3 w-full flex items-center";
  const CLASS = "hover:bg-gray-200 p-3 w-full flex items-center";
  const location = useLocation();
  const navigate = useNavigate();

  const logOut = async (e) => {
    e.preventDefault();
    await signOut(auth);
    navigate("/");
  };

  const Link = ({ name, icon, to, logout }) =>
    logout ? (
      <a href="#" onClick={logOut} className={CLASS}>
        <i class={`fa-sharp fa-solid fa-${icon} mr-3`}></i>
        <span className="hidden md:flex">{name}</span>
      </a>
    ) : (
      <a href={to} className={location.pathname == to ? CLASS_ACTIVE : CLASS}>
        <i class={`fa-sharp fa-solid fa-${icon} mr-3`}></i>
        <span className="hidden md:flex">{name}</span>
      </a>
    );

  return (
    <div className="md:w-[20%] h-full rounded-l-xl bg-gray-100 flex flex-col">
      <Link to={"/"} icon={"house"} name={"Accueil"} />
      <Link to={"/users"} icon={"user"} name={"Utilisateurs"} />
      <Link to={"/matchs"} icon={"handshake"} name={"Matchs"} />
      <Link
        to={"/searchs"}
        icon={"magnifying-glass"}
        name={"Véhicules recherchés"}
      />
      <Link to={"/finds"} icon={"check"} name={"Véhicules retrouvés"} />
      <Link to={`/categories`} icon={"table"} name={"Catégories"} />
      <Link to={`/howitswork`} icon={"pencil"} name={"Comment ça marche"} />
      <Link to={`/cgv`} icon={"pencil"} name={"CGV"} />
      <Link to={`/mentions`} icon={"pencil"} name={"Mentions légales"} />
      <Link logout={true} icon={"right-from-bracket"} name={"Se déconnecter"} />
    </div>
  );
}
