import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import PageTemplate from "../templates/PageTemplate";
import { useLocation } from 'react-router-dom';

export default function MatchDetails() {
  const location = useLocation();
  // const [isLoading, setIsLoading] = useState(true);
  const data = location.state.matchData;


  const Card = ({ title, value, photo }) => (
    <div className="mb-3 leading-6">
      <h3 className="text-lg text-gray-600">{title}</h3>
      {photo ? (
        <a href={value} target="_blank">
          <img className="w-72 h-56 rounded-md" src={value} />
        </a>
      ) : (
        <h5 className="mt-1 text-md font-bold text-black">{value}</h5>
      )}
    </div>
  );

  // if (isLoading) return null;

  return (
    <PageTemplate
      title={"Détails du match"}
      content={
        <div>
          <Card title={"Plaque d'immatriculation"} value={data?.plate} />
          <Card title={"Chercheur"} value={data?.searchEmail} />
          <Card title={"Trouveur"} value={data?.findEmail} />
          <Card
            photo
            title={"Photo du véhicule trouvé"}
            value={data?.findDemandData.imgUrl}
          />
          <Card
            title={"Adresse du véhicule trouvé"}
            value={data?.findDemandData.address}
          />
          
        </div>
      }
    />
  );
}
