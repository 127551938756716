import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  getDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import PageTemplate from "../templates/PageTemplate";
import moment from "moment/moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TextField } from "@mui/material";

export default function Categories() {
  const [data, setData] = useState([]);
  const [newCat, setNewCat] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCategories();
  }, []);

  async function getCategories() {
    const q = query(collection(db, "categories"));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      dataArr.push({
        id: res.id,
        ...res.data(),
      });

    });
    setData(dataArr);
    setIsLoading(false);
  }

  async function addCategories() {
    if (newCat == "") {
      return;
    }
    await addDoc(collection(db, "categories"), {
      name: newCat,
    });
    setNewCat("");
    getCategories();
    alert("Nouvelle catégorie créée");
  }

  async function deleteCategories(item) {
    // await deleteDoc(doc(db, "categories", item));
    // getCategories();
    alert("Bloquée par le DEV");
    // alert("Catégorie supprimée");
  }

  if (isLoading) return null;

  return (
    <PageTemplate
      title={"Catégories de véhicules"}
      content={
        <div>
          {/* <div className="mt-7 mb-3 flex flex-row items-center gap-5">
            <TextField
              className="w-5/6"
              label="Nouvelle catégorie"
              variant="outlined"
              value={newCat}
              onChange={(e) => {
                setNewCat(e.target.value);
              }}
            />
            <button
              className="bg-blue-500 py-4 text-white rounded-md text-sm text-white shadow w-1/6"
              onClick={addCategories}
            >
              ADD
            </button>
          </div> */}
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell>Prix</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <img src={row.image} className={"w-24 h-18 rounded-md"} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.price} €
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <button
                        onClick={() => deleteCategories(row.id)}
                        className="bg-red-500 w-8 h-8 rounded-md flex items-center justify-center"
                      >
                        <i class="fa-solid fa-trash text-xl text-white"></i>
                      </button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    />
  );
}
