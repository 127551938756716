import React from "react";
import PageTemplate from "../templates/PageTemplate";

export default function Home() {
  const CLASS = "hover:bg-gray-100 h-[20vh] w-[49%] rounded-md w-full flex flex-col items-center justify-center";
  const Link = ({ name, icon, to, logout }) => (
    <a href={to} className={CLASS}>
      <i class={`fa-sharp fa-solid fa-${icon} mr-3 text-2xl mb-3`}></i>
      <span>{name}</span>
    </a>
  );

  return (
    <PageTemplate
      title={"Accueil"}
      content={
        <div className="w-full rounded-l-xl bg-white flex flex-row flex-wrap justify-between">
          <Link to={"/"} icon={"house"} name={"Accueil"} />
          <Link to={"/users"} icon={"user"} name={"Utilisateurs"} />
          <Link to={"/matchs"} icon={"handshake"} name={"Matchs"} />
          <Link
            to={"/searchs"}
            icon={"magnifying-glass"}
            name={"Véhicules recherchés"}
          />
          <Link to={"/finds"} icon={"check"} name={"Véhicules retrouvés"} />
          <Link to={`/categories`} icon={"table"} name={"Catégories"} />
          <Link to={`/howitswork`} icon={"pencil"} name={"Comment ça marche"} />
      <Link to={`/cgv`} icon={"pencil"} name={"CGV"} />
      <Link to={`/mentions`} icon={"pencil"} name={"Mentions légales"} />
        </div>
      }
    />
  );
}
