import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import { TextField } from "@mui/material";
import PageTemplate from "../templates/PageTemplate";

export default function CGV() {
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getCGV();
  }, []);

  async function getCGV() {
    const docRef = doc(db, "CGV", "CGV");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setData(docSnap.data().cgv);
    }
    setIsLoading(false);
  }

  async function update() {
    const docRef = doc(db, "CGV", "CGV");

    await updateDoc(docRef, {
      cgv: data,
    });

    alert("Modification éffectuée")
  }

  if (isLoading) return null;

  return (
    <PageTemplate
      title={"CGV"}
      content={
        <div>
          <div className="mt-7 mb-3">
            <TextField
              multiline
              className="w-full"
              label="Ecrire ici"
              value={data}
              variant="outlined"
              onChange={(e) => {
                setData(e.target.value);
              }}
            />
          </div>
          <button
            onClick={update}
            className="w-full py-3 mt-10 rounded-lg bg-blue-500 text-center text-white font-bold shadow"
          >
            Modifier
          </button>
        </div>
      }
    />
  );
}
