import React, { useEffect, useState } from "react";
import { db } from "../config/firebase";
import {
  collection,
  getDocs,
  query,
  where,
  addDoc,
  getDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import PageTemplate from "../templates/PageTemplate";
import moment from "moment/moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";

export default function Matchs() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getMatchs();
  }, []);

  async function getMatchs() {
    const q = query(collection(db, "searchs"), where("finderId", "!=", ""));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const dataArr = [];
      querySnapshot.forEach(async (res) => {
        const getSearchEmail = await getDoc(
          doc(db, "users", res.data().userId)
        );

        const getFindEmail = await getDoc(
          doc(db, "users", res.data().finderId)
        );

        const findDemandData = [];
        const qq = query(
          collection(db, "finds"),
          where("searchPlate", "==", res.data().plate)
        );
        const querySnapshott = await getDocs(qq);

        querySnapshott.forEach(async (res) => {
          findDemandData.push({ id: res.id, ...res.data() });
        });

        dataArr.push({
          id: res.id,
          ...res.data(),
          searchEmail: getSearchEmail.data().email,
          findEmail: getFindEmail.data().email,
          findDemandData: findDemandData[0],
        });
        setData(dataArr);
      });
      setIsLoading(false);
    });
    return () => unsubscribe();
  }

  if (isLoading) return null;

  return (
    <PageTemplate
      title={"Matchs" + " (" + data.length + ")"}
      content={
        <div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Plaque</TableCell>
                  <TableCell>Chercheur</TableCell>
                  <TableCell>Trouveur</TableCell>
                  <TableCell>Matché le</TableCell>
                  <TableCell>Voiture retrouvée ?</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.plate}
                    </TableCell>
                    <TableCell>{row.searchEmail}</TableCell>
                    <TableCell>{row.findEmail}</TableCell>
                    <TableCell>
                      {moment(row.findDemandData.createdAt).format(
                        "DD/MM/YYYY h:mm a"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.isFinish ? (
                        <i class="fa-solid fa-check text-xl text-green-500"></i>
                      ) : (
                        <i class="fa-solid fa-x text-xl text-red-500"></i>
                      )}
                    </TableCell>
                    <TableCell>
                      {!row.isFinish && (
                        <Link
                          to={`/matchs/${row.id}`}
                          state={{ matchData: row }}
                          // href={`/matchs/${row.id}`}
                          className="cursor-pointer"
                        >
                          Voir
                        </Link>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    />
  );
}
