import React, { useState } from "react";
import { TextField } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const login = async () => {
    if (email == "" || password == "") {
      alert("Champs vides");
      return;
    }

    if (
      email !== process.env.REACT_APP_AUTH_EMAIL ||
      password !== process.env.REACT_APP_AUTH_PASSWORD
    ) {
      alert("Logins Incorrects");
      return;
    }
    setIsLoading(true);
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        alert("Une erreur est survenue", "Veuillez réessayer");
      });
  };

  return (
    <div>
      <h1 className="text-4xl font-bold text-center mx-auto mt-4 mb-10">
        Sur4Plots - Admin
      </h1>
      <div className=" max-w-xl mx-auto">
        <div className="w-full px-10 py-10 h-full bg-gray-50 rounded-xl shadow">
          <h2 className="mb-4 font-bold text-2xl">Connexion</h2>
          <div className="mt-7 mb-3">
            <TextField
              className="w-full"
              label="Email"
              value={email}
              variant="outlined"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <TextField
            className="w-full"
            label="Mot de passe"
            value={password}
            variant="outlined"
            type="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <button
            onClick={login}
            className="w-full py-3 mt-10 rounded-lg bg-blue-500 text-center text-white font-bold shadow"
          >
            Connexion
          </button>
        </div>
      </div>
    </div>
  );
}
