import React from "react";
import Sidebar from "../components/Sidebar";

export default function PageTemplate({ title, content }) {
  return (
    <div>
      <h1 className="text-4xl font-bold text-center mx-auto mt-4 mb-5">
        Sur4Plots - Admin
      </h1>
      <div className="h-[80vh] max-w-5xl mx-auto flex flex-row">
        <Sidebar />
        <div className="w-[80%] px-4 py-4 h-full bg-gray-50 rounded-r-xl overflow-y-scroll">
          <h2 className="mb-4 font-bold text-2xl">{title}</h2>
          {content}
        </div>
      </div>
    </div>
  );
}